import React, { useEffect } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import image from "../../assets/images/work-sleep.jpg";

const Section = styled.section`
  overflow: hidden;
`;

export default function BlockPhotoRight({
  id,
  eyebrow = "LEDGER",
  heading = "All balances for every wallet on every chain",
  description = "A detailed ledger with balances of each wallet on every chain.",
  backgroundImage = image,
}) {
  useEffect(() => {
    const fadeElements = gsap.timeline();
    fadeElements.to(`#${id}`, {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from(`#${id} .fade-up`, {
      duration: 0.7,
      opacity: "0",
      y: "20%",
      stagger: {
        amount: "0.5",
      },
    });
  }, []);

  return (
    <Section id={id} className={`pt-[88px] md:pt-24 lg:pt-160 skewElem`}>
      <div className="container relative z-10">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
          <div className="col-span-4 md:col-span-8 lg:col-span-12 mb-50 md:mb-70 lg:mb-90">
            <div className={`eyebrow text-[#EE82EE] fade-up`}>{eyebrow}</div>
          </div>
          <div className="col-span-4 md:col-span-8 lg:col-span-4 lg:max-w-[384px]">
            <h2 className={`h2 mt-6 mb-50 md:mb-90 fade-up`}>{heading}</h2>
            <div className="text-[20px] mt-12 fade-up">{description}</div>
          </div>
          <div className="col-span-4 col-start-1 md:col-span-8 md:col-start-1 lg:col-span-8 lg:col-start-6 mt-50 md:mt-0 md:pb-100 lg:pb-160 pb-8 row-start-1 md:row-auto fade-up">
            <img
              className="block object-cover md:w-[872px] md:h-[545px]  md:object-contain"
              src={backgroundImage}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}
