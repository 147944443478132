import * as React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { renderChart } from "../components/charts/chart";
import { graphql, Link, navigate } from "gatsby";
import MarketCard from "../components/organisms/market-card/market-card";
import SEO from "../components/seo";
import Pagination from "@atlaskit/pagination";
import BlockCTACard from "../components/blocks/BlockCTACard";
import BlockTextButtonRows from "../components/blocks/BlockTextButtonRows";
import BlockTextCTA from "../components/blocks/BlockTextCTA";
import BlockPhotoLeft from "../components/blocks/BlockPhotoLeft";
import BlockPhotoRight from "../components/blocks/BlockPhotoRight";
const PageStyles = styled.div``;

export const MarketCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 377px);
  justify-content: center;
  border-radius: 5px;
  padding: 96px 0px;
`;

export const query = graphql`
  query HomepageMarketsQuery($limit: Int!, $skip: Int!) {
    allShortTermRentalSetAverages(limit: $limit, skip: $skip) {
      edges {
        node {
          name
          shortTermRentalGroup {
            access
            additional_house_rules
            amenities
            weekly_price_factor
            url
            transit
            total_price
            star_rating
            satisfaction_guest
            room_type
            room_and_property_type
            review_score
            review_count
            response_time
            response_rate
            rating_value
            rating_location
            rating_communication
            rating_cleanliness
            rating_checkin
            rating_accuracy
            price_rate_type
            price_rate
            photos
            person_capacity
            notes
            neighborhood_overview
            name
            monthly_price_factor
            longitude
            interaction
            latitude
            description
            city
            charts
            slug
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;

const MarketSet = ({ data }) => {
  useEffect(() => {
    renderChart().catch((e) => window.alert(e.message));
  }, []);

  return (
    <>
      <SEO
        title={`Short Term Rental AirBnB Market Data and Insights for Max Profit`}
        description={`View Short Term Rental AirBnB Market Data on ${data.allShortTermRentalSetAverages.edges
          .map((city) => city.node.shortTermRentalGroup.city)
          .join(
            ", "
          )} including ratings, reviews, prices, amenities needed to be competitive and profitable. Use RentalGuideAI for data visualization.`}
      />
      <PageStyles>
        <div>
          <div>
            <BlockTextCTA
              id="BlockTextCTA"
              heroText={
                "How will you increase occupancy in your short term rentals today?"
              }
              heroText={
                <span>
                  How will you{" "}
                  <span className="font-light italic">increase</span> occupancy
                  in your short term{" "}
                  <span className="font-light italic">rentals</span> today
                </span>
              }
              description={`In a data driven world, RentalGuideAI makes it easy for hosts to achieve their collective goals and create professional experiences for their guests.`}
            />
          </div>
          <BlockPhotoRight
            id="BlockPhotoRight"
            heading={"Optimized interior design for all markets"}
            description={
              "A detailed interior design strategy tailored to each market in each country."
            }
            eyebrow={"Interior Design"}
          />
          <BlockTextButtonRows id="BlockTextButtonRows" />

          <BlockPhotoLeft
            id="BlockPhotoLeft"
            heading={"SEO Optimized listing for maximum profit"}
            description={
              <>
                <ol>
                  <li>Listing Copy</li>
                  <li>Thumbnails</li>
                  <li>Images and Contrast</li>
                  <li>Keywords and Rankings</li>
                  <li>Competitor Research</li>
                  <li>More... </li>
                </ol>
              </>
            }
            eyebrow={"SEO"}
          />
          <MarketCardWrapper>
            {data.allShortTermRentalSetAverages.edges.map((city, index) => {
              return (
                <Link
                  key={`/${city.node.shortTermRentalGroup.slug}`}
                  to={`/${city.node.shortTermRentalGroup.slug}`}
                >
                  <MarketCard
                    index={index}
                    cityName={city.node.name}
                    city={city.node.shortTermRentalGroup}
                  />
                </Link>
              );
            })}
          </MarketCardWrapper>
          <BlockCTACard id="BlockCTACard" />

          <div
            style={{ display: "grid" }}
            className="border top-black py-4 mt-20"
          >
            <Pagination
              defaultSelectedIndex={
                data.allShortTermRentalSetAverages.pageInfo.currentPage || 1
              }
              style={{
                gridColumn: "1/-1",
                justifySelf: "center",
              }}
              pages={Array.from(
                Array(
                  data.allShortTermRentalSetAverages.pageInfo.pageCount
                ).keys()
              )}
              onChange={(event, page, analyticsEvent) => {
                navigate(`/rental-markets-${page}`);
              }}
            />
          </div>
        </div>
      </PageStyles>
    </>
  );
};

export default MarketSet;
