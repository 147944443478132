import React, { useEffect } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import image from "../../assets/images/interor.jpg";

import { media } from "../../styles/media";
const Section = styled.section`
  overflow: hidden;
  .bg--swipe--wrapper {
    position: relative;
    width: 150%;
    right: 0;
    height: 185px;
    @media ${media.mdUp} {
      position: absolute;
      height: 210%;
    }
    @media ${media.lgUp} {
      height: 145%;
    }
  }
  .bg--swipe {
    padding-top: 0;
    @media ${media.mdUp} {
      padding-top: 116%;
    }
  }
`;

export default function BlockPhotoLeft({
  backgroundImage = image,
  eyebrow = "TREASURY",
  heading = "Treasury on the Blockchain",
  description = (
    <>
      <p>Wallet Statements</p>
      <p>Wallet Transfers</p>
      <p>Fiat On & Off Ramps</p>
      <p>Non-Custodial L1 Swaps</p>
      <p>Track All Fee Expenses</p>
    </>
  ),
  id,
}) {
  useEffect(() => {
    const fadeUp = document.querySelectorAll(`#${id} .fade-up`);
    fadeUp.forEach(function (element, index) {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
        },
        duration: 0.5,
        opacity: "0",
        y: "20%",
      });
    });
  }, []);
  return (
    <Section id={id} className={`pt-[88px] md:pt-24 lg:pt-160 skewElem`}>
      <div className="container relative z-10">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
          <div className="row-start-1 md:row-auto col-span-4 col-start-1 md:col-span-8 md:col-start-1 lg:col-span-8 lg:col-start-1 mt-50 md:mt-0 pb-8 md:pb-100 lg:pb-160 fade-up">
            <img
              className="block object-cover md:w-[872px] md:h-[545px]  md:object-contain"
              src={backgroundImage}
            />
          </div>

          <div className="col-start-1 col-span-4 md:col-span-8 lg:col-span-4 lg:col-start-10">
            <div className="col-span-4 md:col-span-8 lg:col-span-12 mb-50 md:mb-70 lg:mb-90">
              <div className={`eyebrow fade-up text-[#EE82EE]`}>{eyebrow}</div>
            </div>
            <h2 className={`h2 mt-6 mb-50 md:mb-90 fade-up`}>{heading}</h2>
            <div className="text-[20px] mt-12  fade-up">{description}</div>
          </div>
        </div>
      </div>
    </Section>
  );
}
