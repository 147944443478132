import React, { useEffect } from "react";
import { gsap } from "gsap";

const BlockTextCTA = ({ heroText, description, id }) => {
  useEffect(() => {
    const fadeElements = gsap.timeline();
    fadeElements.to(`#${id}`, {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from(`#${id} .fade-up`, {
      duration: 0.7,
      opacity: "0",
      y: "20%",
      stagger: {
        amount: "0.5",
      },
    });
  }, []);
  return (
    <div id={id} className="flex justify-center">
      <div className="max-w-[800px] grid text-center fade-up">
        <h1 className=" h1 fade-up">{heroText}</h1>
        <p className="mt-[24px] text-[24px] fade-up">{description}</p>
        <a
          href="mailto:consulting@rentalguideai.com"
          className="btn--rounded justify-self-center btn--border btn--border--black mt-[64px] fade-up"
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default BlockTextCTA;
