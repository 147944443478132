import React from "react";

const BlockCTACard = ({
  heading = "Start today!",
  description = "Interested in learning more? Please reach out to our sales team and we can assist you.",
  cta = "Contact",
  id,
}) => {
  return (
    <div id={id} className={`pt-[80px] md:pt-24 lg:pt-160`}>
      <div className="container relative z-10 ">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 justify-self-center text-center ">
          <div className="pt-8 pb-8 pr-8 pl-8 col-span-12 justify-self-end max-w-[356px] bg-[#272A30] rounded-2xl">
            <h2 className={`h3 text-white mt-6 mb-50 md:mb-90 fade-up`}>
              {heading}
            </h2>
            <p className="text-white font-[24px] mt-[16px]">{description}</p>
            <a
              href="mailto:consulting@rentalguideai.com"
              className="mt-[32px] btn--rounded btn--border btn--border--grey-4 color--grey-6  db btn--inverted"
            >
              {cta}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCTACard;
