import React, { useEffect } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
const Section = styled.section`
  overflow: hidden;
  #button-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    button {
      margin-bottom: 16px;
    }
  }
`;

export default function BlockTextButtonRows({
  id,
  heading = "Hire us for every Short Term Rental & Technical use case",
  text = [
    "SEO",
    "Listing Management",
    "Coaching",
    "Consulting",
    "Hospitality",
    "Interior Design",
    "Online Presence",
  ],
}) {
  useEffect(() => {
    const fadeElements = gsap.timeline();
    fadeElements.to(`#${id}`, {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from(`#${id} .fade-up`, {
      duration: 0.7,
      opacity: "0",
      y: "20%",
      stagger: {
        amount: "0.5",
      },
    });
  }, []);
  return (
    <Section id={id} className={`sm:pt-[88px] md:pt-40`}>
      <div className="container relative z-10 ">
        <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 justify-self-center text-center">
          <div className="col-span-12 justify-self-center max-w-[896px]">
            <h2 className={`h2  mt-6 mb-50 md:mb-90 fade-up`}>{heading}</h2>
          </div>
          <div
            id="button-rows"
            className="col-span-12 mt-16 pb-20 md:pb-100 lg:pb-160 fade-up max-w-[600px] justify-self-center"
          >
            {text.map((word) => {
              return (
                <button className="btn--rounded btn--big mx-2">{word}</button>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
}
